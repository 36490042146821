
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AnyObject } from '@/types';
import Prism from 'vue-prismjs';

import 'prismjs/themes/prism.css';

@Component({
	components: { Prism },
})
export default class SQLViewer extends Vue {
	@Prop(Object) properties!: AnyObject;

	showDialog: boolean = false;

	copySQLToClipboard() {
		navigator.clipboard.writeText(this.code);
	}

	closeDialog() {
		this.showDialog = false;
	}

	get code() {
		return this.properties.sql ?? 'No SQL Data';
	}
}
