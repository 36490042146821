var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-col",
        [
          _c(
            "h2",
            { staticClass: "black--text pb-3" },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-space-between align-center" },
                [
                  _vm._v(" " + _vm._s(_vm.groupTitle) + " "),
                  _c("div", [_vm._t("header")], 2),
                ]
              ),
              _vm.tooltip
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { right: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    {
                                      attrs: {
                                        color: "blue-grey lighten-5",
                                        dark: "",
                                      },
                                    },
                                    on
                                  ),
                                  [_vm._v("info")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3329520550
                      ),
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.description))])]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._l(_vm.paramItems, function (paramItem) {
            return _c("ParametersItem", {
              key: paramItem.id,
              attrs: { paramItem: paramItem },
            })
          }),
          _c("footer", [_vm._t("footer")], 2),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }