var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-avatar",
    { attrs: { color: "contrast", size: _vm.avatarSize } },
    [
      _vm.user.photoURL
        ? _c("img", { attrs: { src: _vm.user.photoURL, alt: "Avatar" } })
        : _c("span", { staticClass: "white--text" }, [
            _vm._v(_vm._s(_vm.initials)),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }