
import { Component, Prop, Vue } from 'vue-property-decorator';
import { User } from '@/types';

@Component
export default class AvatarComponent extends Vue {
	@Prop({ type: Object, required: true }) user!: User;
	@Prop(String) size?: 'x-small' | 'small' | 'x-large';

	get avatarSize(): number {
		switch (this.size) {
			case 'x-small':
				return 24;
			case 'x-large':
				return 180;
			default:
				return 36;
		}
	}

	get initials() {
		if (!this.user.email) return;
		const emailSplit = this.user.email.split('.');

		if (emailSplit.length === 1) return;
		return `${emailSplit[0].charAt(0)}${emailSplit[1].charAt(0)}`.toUpperCase();
	}
}
