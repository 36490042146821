var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "80%" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-chip",
                _vm._g(
                  {
                    staticClass: "mr-2",
                    attrs: {
                      color: "orange",
                      "text-color": "white",
                      disabled: !Boolean(_vm.properties.sql),
                    },
                  },
                  on
                ),
                [_vm._v(" VIEW SQL ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "d-flex justify-space-between text-h5 grey lighten-2",
              attrs: { "primary-title": "" },
            },
            [
              _vm._v(" " + _vm._s(_vm.properties.id) + " "),
              _c(
                "v-btn",
                {
                  attrs: { elevation: "2", icon: "", small: "" },
                  on: { click: _vm.copySQLToClipboard },
                },
                [
                  _c("v-icon", { attrs: { dark: "", small: "" } }, [
                    _vm._v("mdi-content-copy"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("prism", {
                attrs: {
                  code: _vm.code,
                  plugins: ["line-numbers"],
                  language: "sql",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.closeDialog },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }