
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AnyObject } from '@/types';
import SqlViewer from '@/components/data-operations/common/item/overview/SqlViewer.vue';

@Component({
	components: { SqlViewer },
})
export default class ParametersItem extends Vue {
	@Prop({ type: Object, default: { id: 'account', label: 'Account', value: '000021', linkPath: '' } })
	paramItem!: AnyObject;
}
