
import { Component, Prop, Vue } from 'vue-property-decorator';
import ParametersItem from './ParametersItem.vue';

@Component({
	components: { ParametersItem },
})
export default class ParametersList extends Vue {
	@Prop({ type: String, required: true }) groupTitle!: string;
	@Prop(String) description?: string;
	@Prop({ type: Boolean, default: false }) tooltip!: boolean;
	// TODO: Check & remove this prop
	@Prop({
		type: Array,
		default: [
			{ id: 'account', label: 'Account', value: '000020' },
			{ id: 'environment', label: 'Environment', value: 'PROD' },
		],
	})
	paramItems!: object;
}
