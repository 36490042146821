var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "3" } },
        [
          _c("span", { staticClass: "subheading" }, [
            _vm._v(_vm._s(_vm.paramItem.label) + ":"),
          ]),
          _vm.paramItem.description
            ? _c(
                "v-tooltip",
                {
                  attrs: { right: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "v-icon",
                              _vm._g(
                                {
                                  attrs: {
                                    small: "",
                                    color: "blue-grey lighten-5",
                                    dark: "",
                                  },
                                },
                                on
                              ),
                              [_vm._v("info")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    60022831
                  ),
                },
                [_c("span", [_vm._v(_vm._s(_vm.paramItem.description))])]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "9" } },
        [
          _vm.paramItem.component
            ? [
                _c(
                  _vm.paramItem.component,
                  _vm._b(
                    {
                      tag: "component",
                      attrs: { properties: _vm.paramItem.properties },
                    },
                    "component",
                    _vm.paramItem.properties,
                    false
                  )
                ),
              ]
            : [
                _vm.paramItem.value
                  ? [
                      _vm.paramItem.linkPath
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.paramItem.linkPath,
                                target: "_blank",
                              },
                            },
                            [_vm._v(_vm._s(_vm.paramItem.value))]
                          )
                        : _c(
                            "span",
                            { staticClass: "subheading font-weight-bold" },
                            [_vm._v(_vm._s(_vm.paramItem.value))]
                          ),
                    ]
                  : _c(
                      "span",
                      { staticClass: "subheading font-weight-bold grey--text" },
                      [_vm._v(_vm._s(_vm.paramItem.default))]
                    ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }